import { Radio } from "antd";
import { ReactComponent as UserColorful } from "../../components/assets/ic_user_colorful.svg";
import { ReactComponent as CorpColorful } from "../../components/assets/ic_corp_colorful.svg";
import styles from "../styles/radio-role-button.module.scss";
import { RoleType } from "../../store/global-atoms";

type RadioButtonProps = {
    content: string;
    value: RoleType;
    radioClicked?: (role: RoleType) => void;
    isChecked: boolean;
    disabled?: boolean;
};

const RadioRoleButton = (props: RadioButtonProps) => {
    return (
        <div
            style={props.isChecked ? { borderColor: "#FEB111" } : {}}
            className={styles.container}
            onClick={() => {
                if (props.radioClicked) props.radioClicked(props.value);
            }}
        >
            {props.value === "lessor" ? (
                <CorpColorful height={"2.5rem"} width={"auto"} />
            ) : (
                <UserColorful height={"2.5rem"} width={"auto"} />
            )}
            <div>{props.content}</div>
            <Radio disabled={props.disabled} value={props.value} checked={props.isChecked}></Radio>
        </div>
    );
};

export default RadioRoleButton;