import { Checkbox, Col, Collapse, DatePicker, Divider, Dropdown, Form, Input, Menu, Radio, Row, Select, Space, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import accordionStyles from "../../components/styles/accordion.module.scss";
import PrimaryButton from "../../components/atomics/primary-button";
import OnboardLayout from "../../components/layouts/onboard-layout";
import { t, trLocale } from "../../translate";
import { loadingAtom, postloginMessageAtom, signUpAtom } from "../../store/global-atoms";
import { useAtom } from "jotai";
import { ChangeEvent, EventHandler, useEffect, useRef, useState } from "react";
import {
    AuthType,
    BasicModel,
    CategoryModel,
    CityModel,
    MachineDetailModel,
    MachineDocumentViewModel,
    RenterCompanyCreateModel,
    RequestFormCreateWithRegisterModel,
    SocialAuthPageType,
    SocialLoginMessageType,
} from "../../service";
import { findCategory, useSecureService } from "../../store/service-atoms";
import RadioButton from "../../components/atomics/radio-button";
import BoxContainer from "../../components/containers/box-container";
import CheckboxButton from "../../components/atomics/checkbox-button";
import IncrementsInput from "../../components/atomics/increments-input";
import TextArea from "antd/lib/input/TextArea";
import { RangeValue } from "rc-picker/lib/interface";
import moment from "moment";
import UsernameInput from "../../components/atomics/username-input";
import LinkButton from "../../components/atomics/link-button";
import { activeRootSiteUrl, facebookLoginActive, googleLoginActive, redirectRegisterAnonymPageUri } from "../../store/app-constants";
import { ReactComponent as Logo } from "../../components/assets/logo.svg";
import { agreementsVersions } from "../../store/agreements-versions";
import TextButton from "../../components/atomics/text-button";
import PasswordInput from "../../components/atomics/password-input";
import OtpInputField from "../../components/atomics/otp-input";
import { DownOutlined } from "@ant-design/icons";
import ModalLayout from "../../components/layouts/modal-layout";
import { useQuery } from "../../helpers/use-query";
import AgreementReadModal from "../../modals/agreement-read-modal";
import { activeApiBaseUrl } from "../../store/app-constants";
import placeholder from "../../components/assets/placeholder.svg";
import SecondaryButton from "../../components/atomics/secondary-button";
import SocialAuthButton from "../../components/atomics/social-auth-button";
import {ReactComponent as WarningIcon} from "../../components/assets/info-icon-lg.svg";
import { facebookLogin, googleLogin } from "../../utils/auth-utils";
import styles from "./anonym-create-request.module.scss"

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;
const maxCount = 120;

const AnonymRequest = () => {
    const {
        doGetMachineSubcategory,
        doGetExactCities,
        doRenterRegisterWithRequest,
        doResetPasswordOTP,
        doResendActivationCode,
        doResetPassword,
        doGetMachine,
        doSocialLogin,
    } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const navigate = useNavigate();
    const query = useQuery();
    const sid = query.get("sid");
    const macid = query.get("macid");
    const [userId, setUserId] = useState<number>();
    const [allMachineCategory, setAllMachineCategories] = useState<CategoryModel[] | undefined>(undefined);
    const [selectedMachineCategory, setSelectedMachineCategory] = useState<any | undefined>(undefined);
    const [selectedMachineRadio, changeSelectedRadio] = useState<number | undefined>(undefined);
    const [accordionItems, setAccordionItems] = useState<any[] | undefined>(undefined);
    const [selectedCheckboxes, changeSelectedCheckboxes] = useState<any[]>([]);
    const [createCount, setCreateCount] = useState<number | undefined>(undefined);
    const [otherSubCategoryText, setOtherSubCategoryText] = useState<string | undefined>(undefined);
    const [detailText, setDetailText] = useState<string | undefined>(undefined);
    const [form] = Form.useForm();
    const [otpForm] = Form.useForm();
    const [passwordForm] = Form.useForm();
    const [selectedDistrict, setDistrict] = useState<string | undefined>(undefined);
    const [districts, setDistrictsDropdown] = useState<BasicModel[] | undefined>(undefined);
    const [allCities, setAllCities] = useState<CityModel[] | undefined>(undefined);
    const [selectedDates, setSelectedDates] = useState<RangeValue<moment.Moment>>();
    const mediaQueryList = window.matchMedia("(max-width: 658px)");
    const [hasOtp, setHasOtp] = useState<boolean>(false);
    const [showOtp, setShowOtp] = useState<boolean>(false);
    const [otpValue, setOtpValue] = useState<string>();
    const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
    const [counter, setCounter] = useState(maxCount);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [showRequestSuccessModal, setShowRequestSuccessModal] = useState<boolean>(false);
    const [showAlreadyRegisteredModal, setShowAlreadyRegisteredModal] = useState<boolean>(false);
    const [showReadAgreementModal, setShowReadAgreementModal] = useState<boolean>(false);
    const [selectedAgreementSrc, setSelectedAgreementSrc] = useState<string>();
    const [selectedAgreementTitle, setSelectedAgreementTitle] = useState<string>();
    const [machineAllDetail, setMachineAllDetail] = useState<MachineDetailModel>();
    const [machineImages, setMachineImages] = useState<MachineDocumentViewModel[] | undefined>([]);
    const [mainImageId, setMainImageId] = useState<number>();
    const [mainImageUrl, setMainImageUrl] = useState<string>(placeholder);
    const [showRedirectModal, setShowRedirectModal] = useState<boolean>(false);
    const [signUp, setSignUp] = useAtom(signUpAtom);
    const [,setWarningMessage] = useAtom(postloginMessageAtom);
    const Description = <>{t("request.description")}</>;
    const createReqSocialCounterRef = useRef(0);
    const [selectedCity, setCity] = useState<
        | {
              id?: number | null;
              name?: string | null;
              districts?: BasicModel[] | null;
          }
        | undefined
    >(undefined);
    const [userPhone, setUserPhone] = useState<string>();
    const socialLoginQuery = query.get("state")?.split('loginType:')[1];
    const socialLoginType = (socialLoginQuery as AuthType) || null; // code injected by facebook login dialog
    const socialLoginCode = query.get("code"); // code injected by facebook login dialog
    const [socialLoginLoading, setSocialLoginLoading] = useState(query.get("code") !== null);
console.log('login query:', socialLoginQuery, " type:" , socialLoginType)
    const CollapsePanelHeader = (
        <Row gutter={[16, 16]} className={accordionStyles.accordionItemHeader} align="middle" justify="start" wrap={false}>
            <Col xs={6} md={4}>
                <Logo width={"100%"} />
            </Col>
            <Col xs={14} md={18}>
                <h3>{t("common.select")}</h3>
                <span className={accordionStyles.selectText}>
                    {accordionItems?.map((val) => {
                        return val.title + ", ";
                    })}
                </span>
            </Col>
            <Col xs={4} md={4}>
                <DownOutlined style={{ fontSize: "1.25rem" }} />
            </Col>
        </Row>
    );

    useEffect(() => {
        const getMachineDetails = async () => {
            if (macid) {
                const macidN = parseInt(macid);
                if (!Number.isNaN(macid)) {
                    const result = await doGetMachine(macidN, true);
                    if (result) {
                        setMachineAllDetail(result);
                    }
                }
            }
        };
        getMachineDetails();
        //eslint-disable-next-line
    }, []);

    const setSocialRequestAtomWithoutEmail = async (): Promise<boolean> => {
        const formRequest = await getRequestFormValues(form.getFieldsValue());
        if (formRequest !== undefined) {
            setSignUp({
                authType: AuthType.Facebook,
                email: "",
                request: {
                    ...formRequest,
                    withRegister: true,
                    renterUser: {
                        phone:"",
                        sendActivationOTP: true,
                        authType: AuthType.Google,
                        agreementVersion:  agreementsVersions.agreementVersion,
                        kvkkVersion: agreementsVersions.kvkkVersion,
                        ipazVersion: form.getFieldValue('ipazVersion') ? agreementsVersions.ipazVersion : null,
                    }
                }
            });
        } else {
            return false;
        }
        return true;
    }

    useEffect(() => {
        const socialLoginAsync = async (authType: AuthType) => {
            createReqSocialCounterRef.current++;
            var res = await doSocialLogin("customer", {authType: authType, socialToken: socialLoginCode, socialAuthPageType: SocialAuthPageType.Anonymregister});
            switch(res?.type) {
                case SocialLoginMessageType.NotFound:
                case SocialLoginMessageType.SwitchMessage:
                    var signUpObj = {...signUp};
                    if (signUpObj.request) {
                        signUpObj.request.renterUser.email = res.email!!;
                        setSignUp({...signUpObj, name: res.name, lastname: res.lastname, authType: AuthType.Facebook, email: res.email!!});
                        navigate("/signup/form?role=customer&request=true");
                    } else {
                        setSocialLoginLoading(false);
                        setWarningMessage({message: t('request.create.unknown-error-message'), type: 'error'});
                    }
                    break;
                case SocialLoginMessageType.Blocked:
                    setWarningMessage({message: res.message, type: 'error'});  
                    setSocialLoginLoading(false);
                    break;  
                case SocialLoginMessageType.Success:
                    navigate("/dashboard?role=customer&request=true");
                    break;
                case SocialLoginMessageType.Otp:
                    navigate("/sms-verification?id=" + res?.id + "&op=register&phone=" + res?.message + "&role=customer");
                    break;
                default:
                    setSocialLoginLoading(false);
                    break;
            }
        }
        if (socialLoginCode && signUp && createReqSocialCounterRef.current === 0 && socialLoginType !== null) {

            socialLoginAsync(socialLoginType);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUp])

    useEffect(() => {
        if (machineAllDetail?.documents && machineAllDetail.documents.length > 0) {
            const documents = machineAllDetail?.documents?.filter((x: any) => x.documentCategory === "Photo");
            setMachineImages(documents);
        }
    }, [machineAllDetail]);

    useEffect(() => {
        if (machineImages && machineImages?.length > 0) {
            const mainImageId = machineImages[0].document?.id;
            setMainImageId(mainImageId);
        }
        // eslint-disable-next-line
    }, [machineImages]);

    useEffect(() => {
        if (mainImageId === undefined) {
            setMainImageUrl(placeholder);
        } else {
            setMainImageUrl(activeApiBaseUrl + "/document/machine-document/" + mainImageId);
        }
    }, [mainImageId]);

    useEffect(() => {
        const setServiceValues = async () => {
            const result = await doGetMachineSubcategory();
            if (result) setAllMachineCategories(result);
            const cities = await doGetExactCities();
            setAllCities(cities);
        };
        setServiceValues();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const AccordionItems = allMachineCategory?.map((category) => {
            const radios = category.subCategories?.map((sub) => {
                if (sub && sub.id && sub.name) return createSubcategoryRadio(sub.id?.toString(), sub.name, radioClicked);
                else return undefined;
            });
            const returnObj = {
                title: category.name,
                key: category.id,
                children: (
                    <Row gutter={[16, 16]} key={rowKey}>
                        {radios}
                    </Row>
                ),
                description: category.subCategories
                    ?.map((sub) => " " + sub.name)
                    .toString()
                    .trim(),
                imageUrl: "img" + category.id + ".png",
            };
            rowKey++;
            return returnObj;
        });
        setAccordionItems(AccordionItems);
        // eslint-disable-next-line
    }, [allMachineCategory]);

    useEffect(() => {
        let category: CategoryModel | undefined = undefined;
        if (sid && !selectedMachineRadio && allMachineCategory) {
            const sidN = Number.parseInt(sid);
            category = findCategory(sidN);
            handleCategorySelect(category?.name ?? "");
            changeSelectedRadio(sidN);
        }
        // eslint-disable-next-line
    }, [accordionItems]);

    useEffect(() => {
        form.setFieldsValue({ subCategoryOther: undefined });
        setOtherSubCategoryText(undefined);
        changeSelectedRadio(undefined);
        const selectedMachine = allMachineCategory?.find((cat) => cat.id === selectedMachineCategory.key);
        if (selectedMachine?.subCategories?.length === 1 && selectedMachine?.subCategories?.[0]) {
            changeSelectedRadio(selectedMachine.subCategories[0].id);
        }
        // eslint-disable-next-line
    }, [selectedMachineCategory]);

    useEffect(() => {
        if (showOtp) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }
    }, [counter, showOtp]);

    useEffect(() => {
        if (selectedMachineRadio) {
            form.setFieldsValue({ subCategoryOther: undefined });
            setOtherSubCategoryText(undefined);
        }
        // eslint-disable-next-line
    }, [selectedMachineRadio]);

    const checkRequestFields = async (values: any): Promise<boolean> => {
        var agreementCheck = await form.validateFields(['agreementVersion', 'kvkkVersion']);

        if (agreementCheck.errorFields) {
            return false;
        }
        if (!selectedMachineRadio && !macid) {
            setErrorMessage({
                message: t("common.generic-required-subcategory-message"),
                type: "error",
            });
            return false;
        }
        if (!selectedDistrict) {
            setErrorMessage({
                message: t("common.generic-required-district-message"),
                type: "error",
            });
            return false;
        }
        if (values.requestDate === undefined || moment().add(5, "years") < moment(values.requestDate[1]).add(1, "day")) {
            setErrorMessage({
                message: t("request.create.date.validation.end.date"),
                type: "error",
            });
            return false;
        }
        if (values.requestDate === undefined || moment().add(1, "year") < moment(values.requestDate[0]).add(1, "day")) {
            setErrorMessage({
                message: t("request.create.date.validation.start.date"),
                type: "error",
            });
            return false;
        }
        return true;
    }

    const getRequestFormValues = async (values: any): Promise<RequestFormCreateWithRegisterModel | undefined> => {
        let formValues: RequestFormCreateWithRegisterModel = values as RequestFormCreateWithRegisterModel;
        const city = allCities?.find((cty) => cty.name === selectedCity?.name);
        const district = city?.districts?.find((dst) => dst.name === selectedDistrict);
        var checkResult = await checkRequestFields(values);
        if (!checkResult) 
            return undefined;  
        formValues.subCategoryId = macid ? machineAllDetail?.subCategoryId ?? 0 : selectedMachineRadio ?? 0;
        formValues.subCategoryOther = otherSubCategoryText;
        formValues.requestDateFrom = moment(values.requestDate[0]).toDate() || new Date();
        formValues.requestDateTo = moment(values.requestDate[1]).toDate() || new Date();
        formValues.districtId = district?.id ?? 0;
        formValues.withRegister = hasOtp;
        formValues.additionalServices = {
            fuelIncluded: selectedCheckboxes.includes("fuelIncluded"),
            hasOperator: selectedCheckboxes.includes("hasOperator"),
            operatorCostIncluded: selectedCheckboxes.includes("operatorCostIncluded"),
            operatorInsuranceIncluded: selectedCheckboxes.includes("operatorInsuranceIncluded"),
            shipingIncluded: selectedCheckboxes.includes("shipingIncluded"),
        };
        if (macid) {
            formValues.machineId = parseInt(macid);
        }
        
        return formValues;
    }

    const onFormFinish = async (values: any) => {
        let formValues: RequestFormCreateWithRegisterModel | undefined = await getRequestFormValues(values);
        let registerFormValues = values as RenterCompanyCreateModel;
        if (formValues === undefined)
            return;
        formValues.renterUser = {
            ...registerFormValues,
            agreementVersion:  agreementsVersions.agreementVersion,
            kvkkVersion: agreementsVersions.kvkkVersion,
            ipazVersion: values.ipazVersion ? agreementsVersions.ipazVersion : null,
            sendActivationOTP: hasOtp,
            authType: AuthType.Email,
        };
        setUserPhone(registerFormValues.phone);

        if (formValues.renterUser) {
            const request = await doRenterRegisterWithRequest(formValues);
            setUserId(request);
            if (request && hasOtp) {
                setShowOtp(true);
            } else if (request && !hasOtp) {
                setErrorMessage({
                    message: t("request.create.success"),
                    type: "success",
                });
                setShowRequestSuccessModal(true);
            } else {
                setErrorMessage({
                    message: t("common.genericError"),
                    type: "error",
                });
            }
        } else {
            setShowAlreadyRegisteredModal(true);
        }
        
    };

    const handleCategorySelect = (name: string) => {
        var machine = accordionItems?.find((item) => item.title === name);
        setSelectedMachineCategory(machine);
    };

    const dropdownMenu = () => {
        return (
            <Menu style={{ maxHeight: 300, maxWidth: "82vw", width: "auto", overflow: "auto" }}>
                {accordionItems?.map((item) => {
                    const Header = (
                        <div className={accordionStyles.accordionItemHeader}>
                            {item.imageUrl && <img style={{ height: 80, width: 80 }} src={"/" + item.imageUrl} alt={item.imageUrl} />}
                            <div>
                                <h4>{item.title}</h4>
                            </div>
                        </div>
                    );
                    return (
                        <Menu.Item
                            onClick={() => {
                                handleCategorySelect(item.title);
                            }}
                            key={item.id}
                        >
                            {
                                <Panel
                                    style={{ paddingInline: 15 }}
                                    key={item.key}
                                    header={Header}
                                    className={accordionStyles.accordionItemPanel}
                                >
                                    {item.children}
                                </Panel>
                            }
                            <Space></Space>
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
    };

    const createSubcategoryRadio = (value: string, content: string, evt: EventHandler<any>) => {
        return content !== "Diğer " ? (
            <Col xs={24} lg={12}>
                <RadioButton value={value} content={content} radioClicked={evt} />
            </Col>
        ) : (
            <Col xs={24} lg={12}>
                <Form.Item name="subCategoryOther">
                    <Input
                        placeholder={t("request.create.other.subCategory.placeholder")}
                        style={{ height: "3.5rem" }}
                        onChange={(e: any) => {
                            if (e.target.value !== "") {
                                changeSelectedRadio(parseInt(value));
                                setOtherSubCategoryText(e.target.value);
                            } else {
                                changeSelectedRadio(undefined);
                            }
                        }}
                    />
                </Form.Item>
            </Col>
        );
    };

    const radioClicked = (val: any) => {
        changeSelectedRadio(val);
    };

    const checkboxClicked = (val: any) => {
        changeSelectedCheckboxes((old) => {
            if (old.includes(val)) {
                return old.filter((att) => {
                    return att !== val;
                });
            } else return [...old, val];
        });
    };
    const createCountChanged = (num: number) => {
        setCreateCount(num);
    };

    let rowKey = 0;

    const detailTextAreaChanged = (val: ChangeEvent<HTMLTextAreaElement>) => {
        setDetailText(val.currentTarget.value);
    };

    const onCitySelected = async (selectedCityName: string) => {
        setDistrict("");
        const currentCity = allCities?.find((city) => city.name === selectedCityName);
        setCity(currentCity);
        if (currentCity && currentCity.districts) setDistrictsDropdown(currentCity.districts);
        else setDistrictsDropdown(undefined);
    };

    const dateChanged = (val: RangeValue<moment.Moment>) => {
        setSelectedDates(val);
    };

    function disabledDate(current: any) {
        // Can not select days before today and today
        return current && current < moment().endOf("day");
    }

    const otpFormFinishHandler = async (params: any) => {
        setOtpValue(params.sms);
        const result = await doResetPasswordOTP("customer", {
            code: params.sms,
            id: userId ? userId : 0,
        });
        if (result) {
            setShowOtp(false);
            setShowPasswordField(true);
        }
    };

    const resendClick = () => {
        doResendActivationCode("customer", userId ? userId : 0);
        setCounter(maxCount);
    };

    const passwordFormFinishHandler = async (params: { password: string; retype: string }) => {
        const result = await doResetPassword("customer", {
            otpCode: otpValue || "",
            phone: userPhone || "",
            password: params.password,
        });
        if (result) navigate(["/login?role=renter"].join(""));
    };

    const handleSelectedAgreement = (showModal: boolean, linkSrc: string, title: string) => {
        setShowReadAgreementModal(showModal);
        setSelectedAgreementSrc(linkSrc);
        setSelectedAgreementTitle(title);
    };

    return (
        <OnboardLayout smallLogo={true} h2={socialLoginLoading ? '' : Description}>
            {socialLoginLoading ? (
                    <Spin size="large" tip="Lütfen Bekleyiniz" className={styles.spinMobile} /> )
                : (
                <>
                    <Form key={1} name="add-request" layout="vertical" form={form} onFinish={onFormFinish}>                        
                        <Row gutter={[16, 16]} align="middle" justify="center">
                            <Col xs={24} md={24} style={{ textAlign: "center", backgroundColor: "#FFF4E1", paddingTop: 5 }}>
                                <h3>{t("machines.add-info.cardTitle")}</h3>
                            </Col>
                            <Col md={20} xs={20}>
                                {macid ? (
                                    <BoxContainer>
                                        <Row className={accordionStyles.accordionItemHeader} gutter={[0, 8]}>
                                            {machineImages && (
                                                <Col md={2} xs={4}>
                                                    <img src={mainImageUrl} alt={""} />
                                                </Col>
                                            )}
                                            <Col xs={8} md={8}>
                                                <Row style={{ flexDirection: "column" }}>
                                                    <Col>
                                                        <span className={accordionStyles.spanBlack}>{t("request.machine.info.machineId")}</span>
                                                        <span className={accordionStyles.spanBlack}>
                                                            {machineAllDetail?.id ? machineAllDetail?.id.toString() : "-"}
                                                        </span>
                                                    </Col>
                                                    <Col>
                                                        <span className={accordionStyles.spanGrey}>{t("request.machine.info.category")} </span>
                                                        <span className={accordionStyles.spanBlack}>
                                                            {machineAllDetail?.subCategory?.category?.name}
                                                        </span>
                                                    </Col>
                                                    <Col>
                                                        <span className={accordionStyles.spanGrey}>{t("request.machine.info.subCategory")}</span>
                                                        <span className={accordionStyles.spanBlack}>{machineAllDetail?.subCategory?.name}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={8} md={8}>
                                                <Row style={{ flexDirection: "column" }}>
                                                    <Col>
                                                        <span className={accordionStyles.spanGrey}>{t("request.machine.info.brand")} </span>
                                                        <span className={accordionStyles.spanBlack}>
                                                            {machineAllDetail?.brand?.name ? machineAllDetail?.brand.name : "-"}
                                                        </span>
                                                    </Col>
                                                    <Col>
                                                        <span className={accordionStyles.spanGrey}>{t("request.machine.info.model")} </span>
                                                        <span className={accordionStyles.spanBlack}>
                                                            {machineAllDetail?.modelName ? machineAllDetail?.modelName : "-"}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={24} md={4}>
                                                <SecondaryButton onClick={() => setShowRedirectModal(true)} style={{ width: "100%" }}>
                                                    {t("request.machine.info.change.button")}
                                                </SecondaryButton>
                                            </Col>
                                        </Row>
                                    </BoxContainer>
                                ) : (
                                    <div id="area" style={{ position: "relative" }}>
                                        <Dropdown
                                            overlay={dropdownMenu}
                                            trigger={["click"]}
                                            placement="bottom"
                                            getPopupContainer={() => document.getElementById("area")!}
                                        >
                                            <div className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                                {selectedMachineCategory ? (
                                                    <Panel
                                                        header={
                                                            <Row className={accordionStyles.accordionItemHeader} wrap={false}>
                                                                {selectedMachineCategory?.imageUrl && (
                                                                    <Col xs={4} md={2}>
                                                                        <img
                                                                            src={"/" + selectedMachineCategory?.imageUrl}
                                                                            alt={selectedMachineCategory?.imageUrl}
                                                                        />
                                                                    </Col>
                                                                )}
                                                                <Col xs={16} md={20}>
                                                                    <h4>{selectedMachineCategory?.title}</h4>
                                                                    {selectedMachineCategory?.description && (
                                                                        <span>{selectedMachineCategory?.description}</span>
                                                                    )}
                                                                </Col>
                                                                <Col xs={4} md={2}>
                                                                    <DownOutlined style={{ fontSize: "1.25rem" }} />
                                                                </Col>
                                                            </Row>
                                                        }
                                                        key="1"
                                                        className={accordionStyles.accordionItemPanel}
                                                        style={{ padding: 15 }}
                                                    />
                                                ) : (
                                                    <Panel
                                                        header={CollapsePanelHeader}
                                                        key="1"
                                                        className={accordionStyles.accordionItemPanel}
                                                        style={{ padding: 15 }}
                                                    />
                                                )}
                                            </div>
                                        </Dropdown>
                                        {selectedMachineCategory && (
                                            <div>
                                                <Radio.Group value={selectedMachineRadio?.toString()} style={{ width: "100%" }}>
                                                    {selectedMachineCategory?.children}
                                                </Radio.Group>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Col>
                            <Col xs={20} md={20}>
                                <h3>{t("request.view.machineCount")}</h3>
                            </Col>
                            <Col xs={20} md={20}>
                                <BoxContainer style={{ maxHeight: 100 }}>
                                    <Form.Item name="machineCount">
                                        <IncrementsInput min={1} max={50} value={createCount} onChange={createCountChanged} />
                                    </Form.Item>
                                </BoxContainer>
                            </Col>
                            <Col xs={24} md={24} style={{ textAlign: "center", backgroundColor: "#FFF4E1", paddingTop: 5 }}>
                                <h3>{t("machines.add-info.additionalServices")}</h3>
                            </Col>
                            <Col md={20} xs={20}>
                                <BoxContainer>
                                    <Checkbox.Group defaultValue={undefined} value={selectedCheckboxes} style={{ width: "100%" }}>
                                        <Row gutter={[24, 24]}>
                                            <Col xs={24} md={8}>
                                                <CheckboxButton
                                                    content={t("request.machine-detail.hasOperator")}
                                                    value="hasOperator"
                                                    checkboxClicked={checkboxClicked}
                                                />
                                            </Col>
                                            <Col xs={24} md={8}>
                                                <CheckboxButton
                                                    content={t("request.machine-detail.fuelIncluded")}
                                                    value="fuelIncluded"
                                                    checkboxClicked={checkboxClicked}
                                                />
                                            </Col>
                                            <Col xs={24} md={8}>
                                                <CheckboxButton
                                                    content={t("request.machine-detail.shipingIncluded")}
                                                    value="shipingIncluded"
                                                    checkboxClicked={checkboxClicked}
                                                />
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                    <h3>{t("request.machine-detail.writeDetailsHead")}</h3>
                                    <Form.Item name={"description"}>
                                        <TextArea
                                            style={{ marginBottom: "2rem" }}
                                            placeholder={t("request.machine-detail.writeDetailsPlaceholder")}
                                            onChange={detailTextAreaChanged}
                                            value={detailText}
                                        ></TextArea>
                                    </Form.Item>
                                </BoxContainer>
                            </Col>
                            <Col xs={24} md={24} style={{ textAlign: "center", backgroundColor: "#FFF4E1", paddingTop: 5 }}>
                                <h3>{t("request.location-and-date.selectLocationDate")}</h3>
                            </Col>
                            <Col xs={20} md={20}>
                                <BoxContainer>
                                    <Row justify="center">
                                        <Col md={24} xs={24}>
                                            <h3>{t("request.location-and-date.selectLocation")}</h3>
                                            <Row style={{ marginBottom: "1.5rem" }} justify="end" wrap={false}>
                                                <Col xs={12} md={12}>
                                                    <Select
                                                        placeholder={t("request.location.city.placeholder")}
                                                        loading={loading}
                                                        onSelect={onCitySelected}
                                                        style={{ width: "99%" }}
                                                        value={selectedCity?.name}
                                                        getPopupContainer={(trigger: any) => trigger.parentElement}
                                                    >
                                                        {allCities?.map((mc) => (
                                                            <Option value={mc.name}>{mc.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    <Select
                                                        placeholder={t("request.location.district.placeholder")}
                                                        loading={loading}
                                                        style={{ width: "99%" }}
                                                        value={selectedDistrict}
                                                        onSelect={(e: string) => setDistrict(e)}
                                                        getPopupContainer={(trigger: any) => trigger.parentElement}
                                                    >
                                                        {districts?.map((msc) => (
                                                            <Option value={msc.name}>{msc.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={24} xs={24}>
                                            <h3>{t("request.location-and-date.selectdates")}</h3>
                                            <Form.Item
                                                name={"requestDate"}
                                                rules={[
                                                    {
                                                        required: true,
                                                        validator: (_, value) =>
                                                            value
                                                                ? Promise.resolve()
                                                                : Promise.reject(new Error(t("common.generic-required-message"))),
                                                    },
                                                ]}
                                            >
                                                <RangePicker
                                                    value={selectedDates}
                                                    placement="topRight"
                                                    onChange={dateChanged}
                                                    inputReadOnly={mediaQueryList.matches}
                                                    locale={trLocale}
                                                    format="DD-MM-YYYY"
                                                    disabledDate={disabledDate}
                                                    size="large"
                                                    style={{
                                                        width: "100%",
                                                        height: "48px",
                                                        background: "white",
                                                        marginBottom: "1.5rem",
                                                    }}
                                                ></RangePicker>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </BoxContainer>
                            </Col>
                            <Col xs={24} md={24} style={{ textAlign: "center", backgroundColor: "#FFF4E1", paddingTop: 5 }}>
                                <h3>{t("request.customer-info")}</h3>
                            </Col>
                            <Col md={20} xs={20}>
                                <BoxContainer>
                                    <h3>{t("request.contact-title")}</h3>
                                    <Row justify="space-around" align="middle">
                                        <Col xs={20} md={4}>
                                            <Form.Item
                                                label={t("signup.form.nameLabel")}
                                                name="name"
                                                rules={[
                                                    {pattern: /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*$/,message: t('anonym-create-request.name-field')},
                                                    {
                                                        required: true,
                                                        type: "string",
                                                        message: t("signup.form.nameValidation"),
                                                    },
                                                ]}
                                            >
                                                <Input placeholder={t("signup.form.namePlaceholder")} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={20} md={4}>
                                            <Form.Item
                                                label={t("signup.form.surnameLabel")}
                                                name="lastName"
                                                rules={[
                                                    {pattern: /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*$/,message: t('anonym-create-request.surname-field')},
                                                    {
                                                        required: true,
                                                        type: "string",
                                                        message: t("signup.form.surnameValidation"),
                                                    },
                                                ]}
                                            >
                                                <Input placeholder={t("signup.form.surnamePlaceholder")} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={20} md={4}>
                                            <Form.Item
                                                label={t("signup.form.emailLabel")}
                                                name="email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        type: "email",
                                                        message: t("signup.form.emailValidation"),
                                                    },
                                                ]}
                                            >
                                                <Input placeholder={t("signup.form.emailPlaceholder")} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={20} md={4}>
                                            <UsernameInput prefix={false} />
                                        </Col>
                                        <Col xs={20} md={4}>
                                            <Form.Item
                                                label={t("signup.form.corpLabel")}
                                                name="companyName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        type: "string",
                                                        message: t("signup.form.corpValidation"),
                                                    },
                                                ]}
                                            >
                                                <Input placeholder={t("signup.form.corpPlaceholder")} />
                                            </Form.Item>
                                        </Col>
                                        <Row justify="center" gutter={6}>
                                            <Col xs={4} md={1} style={{display: 'flex', alignItems: 'center'}}>
                                                <WarningIcon  width={'100%'}/>
                                            </Col>
                                            <Col xs={20} md={23} style={{display: 'flex', alignItems: 'center',textAlign: 'center'}}>
                                                <span>{t("request.send-with-register.social-message")}</span>
                                            </Col>
                                        </Row>
                                    </Row>
                                </BoxContainer>
                            </Col>
                            <Col xs={20} md={20}>
                                <Form.Item
                                    name="agreementVersion"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            required: true,
                                            type: "boolean",
                                            message: t("signup.form.bhsValidation"),
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                        },
                                    ]}
                                >
                                    <Checkbox>
                                        <LinkButton
                                            onClick={() =>
                                                handleSelectedAgreement(true, activeRootSiteUrl + "/kullanici-sozlesmesi/", "agreementVersion")
                                            }
                                            target="_blank"
                                        >
                                            {t("signup.form.bhsName")}
                                        </LinkButton>
                                        <span> {t("signup.form.bhsLabel")}</span>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={20} md={20}>
                                <Form.Item
                                    name="kvkkVersion"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            type: "boolean",
                                            message: t("signup.form.kvkkValidation"),
                                            required: true,
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                        },
                                    ]}
                                >
                                    <Checkbox>
                                        <LinkButton
                                            onClick={() => handleSelectedAgreement(true, activeRootSiteUrl + "/aydinlatma-metni/", "kvkkVersion")}
                                            target="_blank"
                                        >
                                            {t("signup.form.kvkkName")}
                                        </LinkButton>
                                        <span> {t("signup.form.kvkkLabel")}</span>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={20} md={20}>
                                <Form.Item
                                    name="ipazVersion"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            type: "boolean",
                                            message: t("signup.form.ipazValidation"),
                                        },
                                    ]}
                                >
                                    <Checkbox>
                                        <LinkButton
                                            onClick={() =>
                                                handleSelectedAgreement(true, activeRootSiteUrl + "/izinli-pazarlama-onay-metni/", "ipazVersion")
                                            }
                                            target="_blank"
                                        >
                                            {t("signup.form.ipazName")}
                                        </LinkButton>
                                        <span> {t("signup.form.ipazLabel")}</span>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Divider />
                            </Col>
                            <Row justify="center" style={{width: '100%'}} gutter={[12,12]}>
                                <Col hidden={!googleLoginActive} xs={24} md={6}>
                                    <SocialAuthButton type="google" onClick={async () => {
                                        var check = await setSocialRequestAtomWithoutEmail();
                                        if (!check)
                                            return;
                                        else
                                            googleLogin(redirectRegisterAnonymPageUri, "loginType:" + AuthType.Google);
                                    }} text={t('signup.google.button-text')}/>       
                                </Col>
                                <Col hidden={!facebookLoginActive} xs={24} md={6}>
                                    <SocialAuthButton type="facebook" onClick={async () => {
                                        var check = await setSocialRequestAtomWithoutEmail();
                                        if (!check)
                                            return;
                                        else
                                            facebookLogin(redirectRegisterAnonymPageUri, "loginType:" + AuthType.Facebook);
                                    }} text={t('signup.facebook.button-text')}/>                  
                                </Col>
                            </Row>                        
                            {!(showOtp || showPasswordField) && (
                                <Col md={20} style={{marginBottom: "5rem"}}>
                                    <Row align="middle" justify="space-around">
                                        <Col
                                            md={10}
                                            xs={18}
                                            onClick={() => {
                                                setHasOtp(false);
                                            }}
                                        >
                                            <Form.Item>
                                                <PrimaryButton
                                                    loading={loading}
                                                    htmlType="submit"
                                                    style={{ marginTop: "1rem", backgroundColor: "transparent", border: "none", color: "grey" }}
                                                >
                                                    {t("request.send-without-register")}
                                                </PrimaryButton>
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            md={10}
                                            xs={18}
                                            onClick={() => {
                                                setHasOtp(true);
                                            }}
                                        >
                                            <Form.Item>
                                                <PrimaryButton loading={loading} htmlType="submit" style={{ marginTop: "1rem" }}>
                                                    {t("request.send-with-register")}
                                                </PrimaryButton>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Form>
                    {showOtp && (
                        <Row gutter={[16, 16]} align="middle" justify="center">
                            <Form key={2} name={"form-otp"} className="login-form" layout="vertical" form={otpForm} onFinish={otpFormFinishHandler}>
                                <Row align="middle" justify="center">
                                    <Col xs={20} md={24} style={{ marginBottom: "5%" }}>
                                        {t("sms-verification.info-message")}
                                    </Col>
                                </Row>
                                <Row align="middle" justify="center">
                                    <Col xs={18} md={14}>
                                        <TextButton onClick={() => resendClick()} type="link" disabled={counter !== 0}>
                                            {t("sms-verification.resend")}
                                            {counter !== 0 && <span>{`(${counter} s)`}</span>}
                                        </TextButton>
                                    </Col>
                                    <Col xs={22} md={14}>
                                        <OtpInputField otpValue={otpValue} onChange={(e: string) => setOtpValue(e)} numInputs={4} />
                                    </Col>
                                    <Col xs={16} md={16}>
                                        <PrimaryButton loading={loading} htmlType="submit">
                                            {t("common.continue")}
                                        </PrimaryButton>
                                    </Col>
                                </Row>
                            </Form>
                        </Row>
                    )}
                    {showPasswordField && (
                        <Row gutter={[16, 16]} align="middle" justify="center">
                            <Row align="middle" justify="center">
                                <Col xs={20} md={16} style={{ textAlign: "center" }}>
                                    {t("signup.form.passwordInfo")}
                                </Col>
                            </Row>
                            <Col xs={20} md={6}>
                                <Form
                                    key={3}
                                    name={"change-password"}
                                    className="login-form"
                                    layout="vertical"
                                    form={passwordForm}
                                    onFinish={passwordFormFinishHandler}
                                >
                                    <div>
                                        <PasswordInput
                                            placeholder={t("reset-password.first.placeholder")}
                                            validationMessage={t("reset-password.first.validationMessage")}
                                            label={t("reset-password.first.label")}
                                        />
                                    </div>
                                    <div>
                                        <PasswordInput
                                            again
                                            placeholder={t("reset-password.second.placeholder")}
                                            validationMessage={t("reset-password.second.validationMessage")}
                                            label={t("reset-password.second.label")}
                                        />
                                    </div>
                                    <div>
                                        <PrimaryButton loading={loading} htmlType="submit">
                                            {t("common.save")}
                                        </PrimaryButton>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    )}
                    <ModalLayout
                        show={showRequestSuccessModal}
                        onCloseClick={() => {
                            setShowRequestSuccessModal(false);
                        }}
                    >
                        <Row wrap={true} justify="center" align="middle">
                            <Col md={16} xs={16}>
                                <h2 style={{ textAlign: "center" }}>{t("request.create.success.modal.title")}</h2>
                            </Col>
                            <Col md={24} xs={24}>
                                <p style={{ textAlign: "center" }}>{t("request.create.success.modal.text")}</p>
                            </Col>
                            <Col md={24} xs={24}>
                                <PrimaryButton
                                    onClick={() => {
                                        window.location.replace(activeRootSiteUrl);
                                    }}
                                >
                                    {t("request.create.success.modal.button.title")}
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </ModalLayout>
                    <ModalLayout
                        show={showAlreadyRegisteredModal}
                        onCloseClick={() => {
                            setShowAlreadyRegisteredModal(false);
                        }}
                    >
                        <Row wrap={true} justify="center" align="middle">
                            <Col md={16} xs={16}>
                                <h2 style={{ textAlign: "center" }}>{t("request.create.already.registered.modal.title")}</h2>
                            </Col>
                            <Col md={12} xs={16}>
                                <p style={{ textAlign: "center" }}>{t("request.create.already.registered.modal.text")}</p>
                            </Col>
                            <Col md={24} xs={24}>
                                <PrimaryButton
                                    onClick={() => {
                                        navigate(["/login?role=renter"].join(""));
                                    }}
                                >
                                    {t("common.signin")}
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </ModalLayout>
                    {selectedAgreementTitle && selectedAgreementSrc && (
                        <AgreementReadModal
                            show={showReadAgreementModal}
                            title={selectedAgreementTitle}
                            agreementSrc={selectedAgreementSrc}
                            onCloseClick={() => {
                                setShowReadAgreementModal(false);
                            }}
                            onButtonClick={() => {
                                form.setFieldsValue({ [selectedAgreementTitle]: true });
                                setShowReadAgreementModal(false);
                            }}
                        />
                    )}
                    {
                        <ModalLayout
                            show={showRedirectModal}
                            onCloseClick={() => {
                                setShowRedirectModal(false);
                            }}
                        >
                            <Row gutter={[16, 16]} wrap={true} justify="center" align="middle">
                                <Col md={16} xs={16}>
                                    <h3 style={{ textAlign: "center" }}>{t("request.create.redirect.modal.title")}</h3>
                                </Col>
                                <Col md={16} xs={16} style={{ display: "flex", alignItems: "center" }}>
                                    <PrimaryButton
                                        onClick={() => {
                                            window.open(activeRootSiteUrl + `/kiralik-is-makineleri`, "_self");
                                        }}
                                        style={{ width: "300px" }}
                                    >
                                        {t("common.ok")}
                                    </PrimaryButton>
                                </Col>
                            </Row>
                        </ModalLayout>
                    }
                </>
            )}
        </OnboardLayout>
    );
};

export default AnonymRequest;
