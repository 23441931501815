import { Form, Input } from "antd";
import { LockOutlined } from "@ant-design/icons";

export type PasswordInputProps = {
    validationMessage?: string;
    placeholder?: string;
    label?: string;
    again?: boolean;
};

const PasswordInput = (props: PasswordInputProps) => {
    const name = props.again ? "retype" : "password";
    return (
        <Form.Item name={name} label={props.label} rules={[{ required: true, message: props.validationMessage }]}>
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder={props.placeholder} />
        </Form.Item>
    );
};
export default PasswordInput;
