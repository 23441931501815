import { Col, Form, Input, Row } from "antd";
import { t } from "../../translate";

export type EmailInputProps = {
    initialValue?: string;
};

const validateEmailOrPhone = (_: any, value: string) => {
    if (!value) {
        return Promise.reject(t("login.email-error"));
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(0?[1-9][0-9]{9})$/;

    if (!emailRegex.test(value) && !phoneRegex.test(value)) {
        return Promise.reject(t("login.email-error"));
    }

    return Promise.resolve();
};

const EmailInput = (props: EmailInputProps) => {
    return (
        <Form.Item
            name="email"
            label={t("login.email.input-label")}
            validateTrigger={['onBlur', 'onChange']} 
            rules={[
                {
                    required: true,
                    message: t("login.email-or-number-validation"),
                },
                {
                    validator: validateEmailOrPhone,
                },
            ]}
        >
            <Row>
                <Col xs={24}>
                    <Input placeholder={t("login.email.input-placeholder")} />
                </Col>
            </Row>
        </Form.Item>
    );
};

export default EmailInput;
