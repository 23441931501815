import { Col, Form, Row } from "antd"
import ModalLayout from "../components/layouts/modal-layout";
import SmsInput from "../components/atomics/sms-input";
import { t } from "../translate";
import PrimaryButton from "../components/atomics/primary-button";
import styles from "./styles/sms-verification-modal.module.scss"
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { loadingAtom } from "../store/global-atoms";

type SMSVerificationModalProps = {
    type: 'userDeletion';
    show: boolean;
    phone: string;
    onSendOtpClicked: (otp: string) => void;
    onCloseClick: () => void;
}

const SMSVerificationModal = (props: SMSVerificationModalProps) => {
    const [loading] = useAtom(loadingAtom);

    const [counter, setCounter] = useState(120);
    const formFinishHandler = async (params: { sms: string }) => {
        if (params.sms.length > 0) {
            props.onSendOtpClicked(params.sms)
        }
    }; 

    useEffect(() => {
        counter > 0 && setTimeout(() => {
            if (counter === 1) {
                props.onCloseClick();
            }
            setCounter(counter - 1);
        }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);

    return (
        <ModalLayout key={'smsverificationmodal-' + props.show} show={props.show} onCloseClick={props.onCloseClick}>
            <Form name="delete-verification" onFinish={formFinishHandler}>
                <Row className={styles.container} justify="center">
                    <Row className={styles['item-container']}>
                        <Col className={styles['text-container']}>
                            <span>{props.phone + ' ' + t("delete-account-sms-question")}</span>
                        </Col>
                    </Row>
                    <Row className={styles['item-container']}>
                        <Col>
                            <SmsInput
                                placeholder={t("sms-verification.smsInput.placeholder")}
                                validationMessage={t("sms-verification.smsInput.validationMessage")}
                                label={t("sms-verification.smsInput.label")}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {counter !== 0 && <span className={styles.counter}>{`(${counter} s)`}</span>}
                    </Row>
                    <Row gutter={[12,12]} className={styles['item-container']}>
                        <Col xs={24} md={11}>
                            <PrimaryButton loading={loading} onClick={() => props.onCloseClick()}>
                                {t("common.cancel")}
                            </PrimaryButton>
                        </Col>
                        <Col xs={24} md={11}>
                            <PrimaryButton loading={loading} htmlType="submit">
                                {t("common.continue")}
                            </PrimaryButton>                 
                        </Col>
                    </Row>
                </Row>
            </Form>
        </ModalLayout>
    );
}

export default SMSVerificationModal;