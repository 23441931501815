import styles from "../styles/card-container.module.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import { MouseEventHandler } from "react";
import { ReactComponent as UserColorful } from "../../components/assets/ic_user_colorful.svg";
import { ReactComponent as CorpColorful } from "../../components/assets/ic_corp_colorful.svg";

export type CardButtonProps = {
    icon: 'user' | 'corp';
    label: string;
    selected?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
};

const CardButton = (props: CardButtonProps) => {
    const cursorString = !!props.onClick ? "pointer" : "default";

    return (
        <div className={(props.selected ? styles['selected-card'] : styles['unselected-card'])} onClick={props.onClick} style={{ cursor: cursorString }}>
            {props.icon === 'user' ? 
                <UserColorful className={styles.icon} />
                :
                props.icon === 'corp' ? 
                <CorpColorful className={styles.icon} />
                :
                undefined
            }
            <span className={styles.text}>{props.label}</span>
            <ArrowRightOutlined style={{ color: "#FEB111", fontSize: "2rem" }} />
        </div>
    );
};

export default CardButton;
