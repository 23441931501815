import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import BigboxContainer from "../components/containers/bigbox-container";
import { CityModel, LessorCompanyInfoModel } from "../service";
import { loadingAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import { FormInstance } from "antd/es/form/Form";

const MachineServiceRegionsPart = (props: {form?: FormInstance<any>}) => {
    const [companyDetails, setCompanyDetails] = useState<LessorCompanyInfoModel | undefined>(undefined);
    const { doGetLessorCompanyDetails } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const { doGetCities } = useSecureService();
    const [cities, setCities] = useState<CityModel[] | undefined>(undefined);

    useEffect(() => {
        const getCities = async () => {
            const result = await doGetCities();
            setCities(result);
        };
        getCities();
        doGetLessorCompanyDetails().then((result) => {
            setCompanyDetails(result);
            props.form?.setFieldsValue({
                serviceRegionsList: result?.serviceRegions?.map((city) => city.name) ?? undefined,
            });
            
        });

        //eslint-disable-next-line
    }, []);


    let scities = companyDetails?.serviceRegions?.map((city) => city.name);

    return (
        <BigboxContainer title={t("machines.add.serviceRegionsTitle")}>
            {scities && scities.length > 0 && (
                <Form.Item
                    initialValue={scities}
                    name="serviceRegionsList"
                    label={t("machines.add-info.selectRegions")}
                    rules={[
                        {
                            required: true,
                            message: t("common.generic-required-message"),
                        },
                    ]}
                >
                    <Select getPopupContainer={(trigger: any) => trigger.parentElement} mode="multiple" onKeyDown={(event) => {
                        if (/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }} loading={loading}>
                        {cities?.map((city) => (
                            <Select.Option value={city.name}>{city.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
        </BigboxContainer>
    );
};

export default MachineServiceRegionsPart;
