import { Col, Form, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai";
import PrimaryButton from "../../../components/atomics/primary-button";
import SecureLayout from "../../../components/layouts/secure-layout";
import { useQuery } from "../../../helpers/use-query";
import MachineAdditionalInfoPart from "../../../page-parts/machine-additional-info-part";
import MachineAdditionalServicesPart from "../../../page-parts/additional-services-part";
import MachineMachineInfoPart from "../../../page-parts/machine-machine-info-part";
import MachineServiceRegionsPart from "../../../page-parts/machine-service-regions-part";
import { extendedMachineDetailAtom } from "../../../store/global-atoms";
import { t } from "../../../translate";
import { useSecureService } from "../../../store/service-atoms";
import { BasicModel, DocumentCategory, DocumentModel, MachineDocumentViewModel, MachineRentalTypeModel } from "../../../service";
import { useNavigate } from "react-router-dom";

const EditMachine = () => {
    const query = useQuery();
    const companyId = query.get("compid");
    const machineId = query.get("macid");
    const rentalFromSale = query.get("s");
    const [form] = Form.useForm();
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const { doGetRentalTypes, doGetMachine, doUpdateMachine, doCreateRentalFromSale, doGetCurrencies } = useSecureService();
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const [availableRentalTypes, setAvailableRentalTypes] = useState<BasicModel[] | undefined>(undefined);
    const [docs, setDocs] = useState<MachineDocumentViewModel[]>([]);
    const [dataFetching, setDataFetching] = useState(true);
    const [machineInfoDataFetching, setMachineInfoDataFetching] = useState(false);
    const navigate = useNavigate();

    const pics = useMemo(() => {
        return editedMachine?.documents?.filter((doc) => doc.documentCategory === "Photo");
    }, [editedMachine?.documents]);
    const otherDocs = useMemo(() => {
        return editedMachine?.documents?.filter((doc) => doc.documentCategory !== "Photo");
    }, [editedMachine?.documents]);

    useEffect(() => {
        if (machineId) {
            getMachine(Number.parseInt(machineId));
        } else {
            editMachine((mach) => {
                return {
                    ...mach,
                    lessorCompanyId: companyId ? parseInt(companyId) : undefined,
                    id: machineId ? parseInt(machineId) : undefined,
                };
            });
        }
        const getRentalTypes = async () => {
            const result = await doGetRentalTypes();
            setAvailableRentalTypes(result);
        };

        const getCurrencies = async () => {
            const currencies = await doGetCurrencies();
            setAvailableCurrencies(currencies);
        };

        getRentalTypes();
        getCurrencies();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (availableCurrencies && availableRentalTypes && machineInfoDataFetching) {
            setTimeout(() => {
                setDataFetching(false);
            }, 1500);
        }
    }, [availableCurrencies, availableRentalTypes, machineInfoDataFetching]);

    useEffect(() => {
        interface ExtendedObject {
            [key: string]: any;
        }

        const addedForEditing: ExtendedObject = {};
        editedMachine?.rentalTypes?.forEach((rental) => {
            if (rental.rentalType !== undefined && rental.rentalType.name !== null && rental.rentalType.name !== undefined) {
                const checkName: string = "check" + rental.rentalType.name;
                const inputName: string = "input" + rental.rentalType.name;
                const selectName: string = "select" + rental.rentalType.name;

                addedForEditing[checkName] = true;
                addedForEditing[inputName] = rental.price;
                addedForEditing[selectName] = rental.currency;
            }
        });
        let editedMachineTemp = {...editedMachine};
        delete editedMachineTemp.serviceRegionsList;
        delete editedMachineTemp.serviceRegions;
        form.setFieldsValue({ ...editedMachineTemp, ...addedForEditing, categoryName: editedMachine?.subCategory?.category?.name , otherBrandName: editedMachine?.brandOther });
        //eslint-disable-next-line
    }, [editedMachine]);

    const getMachine = async (machineId: number) => {
        const result = await doGetMachine(machineId, true);
        if (result?.documents) setDocs(result?.documents);
        
        editMachine(result);
    };

    const onMachineFormFinish = async (params: any) => {
        let rentalTypes: MachineRentalTypeModel[] = [];
        availableRentalTypes?.forEach((rtype) => {
            if (params["check" + rtype.name] && params["input" + rtype.name]) {
                let rental: MachineRentalTypeModel = {
                    currency: availableCurrencies?.find((x) => x.name === params["select-" + rtype.name]),
                    price: params["input" + rtype.name],
                    rentalType: {
                        id: rtype.id,
                        name: rtype.name,
                    },
                };
                rentalTypes.push(rental);
            }
        });
        //editedMachine.rental
        let result = false;
        if (rentalFromSale === 't') {
            result = await doCreateRentalFromSale({
                ...editedMachine,
                ...params,
                rentalTypes: rentalTypes,
                documents: docs,
            });
        }
        else {
            result = await doUpdateMachine({
                ...editedMachine,
                ...params,
                rentalTypes: rentalTypes,
                documents: docs,
            });
        }
        if (result) {
            editMachine(undefined);
            navigate("/machines", { replace: true });
        }
    };

    const uploadedFilesChanged = (docs: DocumentModel[], category: DocumentCategory) => {
        setDocs((oldVal) => {
            var otherCategories = oldVal?.filter((doc) => doc.documentCategory !== category);
            if (!otherCategories) otherCategories = [];
            var addedDocs: MachineDocumentViewModel[] = docs.map((doc) => {
                return {
                    documentCategory: category,
                    document: doc,
                };
            });
            otherCategories?.push(...addedDocs);

            return [...otherCategories];
        });
    };

    const allMachineInfoDataFetched = () => {
        setMachineInfoDataFetching(true);
    }
    
    return (
        <SecureLayout loading={dataFetching} activePage={"machines"} role={"lessor"} title={t("machines.add.title")} description={t("machines.add.description")}>
            <Form name="edit-machine" initialValues={editedMachine} form={form} onFinish={onMachineFormFinish} layout="vertical">
                <Row gutter={[4, 4]}>
                    <Col xs={24} md={12}>
                        <MachineMachineInfoPart allDataFetched={allMachineInfoDataFetched} uploadChange={uploadedFilesChanged} docs={pics} form={form} />
                    </Col>

                    <Col xs={24} md={12}>
                        <MachineAdditionalInfoPart uploadChange={uploadedFilesChanged} docs={otherDocs} form={form} />
                    </Col>

                    <Col xs={24} md={12}>
                        <MachineAdditionalServicesPart form={form} />
                    </Col>

                    <Col xs={24} md={12}>
                        <MachineServiceRegionsPart form={form} />
                    </Col>
                    <Col xs={6} offset={9}>
                        <Form.Item>
                            <PrimaryButton htmlType="submit" style={{ marginTop: "1rem" }}>
                                {t("common.save")}
                            </PrimaryButton>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </SecureLayout>
    );
};

export default EditMachine;
