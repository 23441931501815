import OnboardLayout from "./onboard-layout";
import styles from "../styles/auth-layout.module.scss";
import excavator from "../assets/excavator.png";
import forklift from "../assets/forklift.png";

export type LoginLayoutProps = {
    children: any;
    smallLogo?: boolean;
};

const AuthLayout = (props: LoginLayoutProps) => {
    return (
        <OnboardLayout footerVisible={true} smallLogo={props.smallLogo}>
            <div className={styles.formColumnContainer}>{props.children}</div>
            <div className={styles.excavatorContainer}>
                <img src={excavator} alt="excavator" />
            </div>
            <div className={styles.forkliftContainer}>
                <img src={forklift} alt="forklift" />
            </div>
        </OnboardLayout>
    );
};

export default AuthLayout;
