import styles from "../styles/tab-button.module.scss";
import { MouseEventHandler } from "react";

export type TabButtonProps = {
    icon: any;
    label: string;
    active?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
};

const TabButton = (props: TabButtonProps) => {
    const cursorString = !props.active ? "pointer" : "default";
    const activeStyle = props.active ? styles.activeTabButton : undefined;

    return (
        <div
            className={`${styles.tabButton} ${activeStyle}`}
            onClick={(e) => {
                if (!!!props.active && props.onClick) props.onClick(e);
            }}
            style={{ cursor: cursorString }}
        >
            {props.icon}
            <span style={{ width: "100%" }}>{props.label}</span>
        </div>
    );
};

export default TabButton;
