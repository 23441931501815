import { message } from "antd";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ForgotPassword from "./pages/auth/forgot-password";
import Login from "./pages/auth/login";
import ResetPassword from "./pages/auth/reset-password";
import SmsVerification from "./pages/auth/sms-verification";
import List from "./pages/list";
import AnonymRequest from "./pages/request/anonym-create-request";
import LocationAndDate from "./pages/request/location-and-date";
import MachineDetail from "./pages/request/machine-detail";
import SelectMachine from "./pages/request/select-machine";
import Dashboard from "./pages/secure/dashboard";
import LessorProfile from "./pages/secure/lessor-profile";
import AddMachine from "./pages/secure/machines/add";
import EditMachine from "./pages/secure/machines/edit";
import ListMachines from "./pages/secure/machines/list";
import ReviewMachine from "./pages/secure/machines/review";
import ViewMachine from "./pages/secure/machines/view";
import Notifications from "./pages/secure/notifications";
import AddOffer from "./pages/secure/offers/add";
import EditOffer from "./pages/secure/offers/edit";
import ListOffers from "./pages/secure/offers/list";
import ViewOffer from "./pages/secure/offers/view";
import RentalMachineDetail from "./pages/secure/rental-machines/detail";
import ListRentalMachines from "./pages/secure/rental-machines/list";
import CustomerProfile from "./pages/secure/customer-profile";
import AddRequestsAndOffers from "./pages/secure/requests-and-offers/add";
import AddLocationAndDate from "./pages/secure/requests-and-offers/add-location-and-date";
import AddMachineDetail from "./pages/secure/requests-and-offers/add-machine-detail";
import ListRequestsAndOffers from "./pages/secure/requests-and-offers/list";
import EditRequest from "./pages/secure/requests/edit";
import TagManager from "react-gtm-module";
import ListRequests from "./pages/secure/requests/list";
import ViewRequest from "./pages/secure/requests/view";
import Signup from "./pages/signup";
import SignupForm from "./pages/signup/form";
import { postloginMessageAtom } from "./store/global-atoms";
import ListSaleMachines from "./pages/secure/sale-machines/list";
import SaleMachineDetail from "./pages/secure/sale-machines/detail";
import ListLessorSaleMachines from "./pages/secure/lessor-sale-machines/list";
import AddSaleMachine from "./pages/secure/sale-machines/add";
import EditSaleMachine from "./pages/secure/sale-machines/edit";
import { PaymentRedirect } from "./pages/payment/payment-redirect";
import Agreement from "./pages/agreement/agreement";
import AboutUs from "./pages/auth/about-us";

const tagManagerArgs = {
    gtmId: "GTM-MV75S7Z",
};
TagManager.initialize(tagManagerArgs);
export const logoutChannel = new BroadcastChannel("MG_LOGGED_OUT_NOTIFICATION");
export const switchBroadcastChannel = new BroadcastChannel("MG_LOGGED_IN_NOTIFICATION_SWITCH");

function App() {
    const [toastMessage] = useAtom(postloginMessageAtom);
    const navigate = useNavigate();

    useEffect(() => {
        switchBroadcastChannel.onmessage = async (e) => {
            if (e.data === "MG_LOGGED_IN_NOTIFICATION_SWITCH") {
                navigate("/dashboard");
            }
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        logoutChannel.onmessage = async (e) => {
            if (e.data === "MG_LOGGED_OUT_NOTIFICATION_LESSOR") {
                navigate("/login?role=lessor");
            }
            if (e.data === "MG_LOGGED_OUT_NOTIFICATION_RENTER") {
                navigate("/login?role=renter");
            }
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (toastMessage.message === "") return;
        switch (toastMessage.type) {
            case "error":
                message.error(toastMessage.message);
                break;
            case "info":
                message.info(toastMessage.message);
                break;
            case "warning":
                message.warning(toastMessage.message);
                break;
            case "success":
                message.success(toastMessage.message);
                break;
            case "loading":
                message.loading(toastMessage.message);
                break;
        }
        toastMessage.message = "";
    }, [toastMessage]);

    return (
        <Routes>
            <Route path="/" element={<List />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/payment-redirect" element={<PaymentRedirect />} />
            <Route path="/signup/form" element={<SignupForm />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/sms-verification" element={<SmsVerification />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/request/select-machine" element={<SelectMachine />} />
            <Route path="/request/location-and-date" element={<LocationAndDate />} />
            <Route path="/request/machine-detail" element={<MachineDetail />} />
            <Route path="/request/create" element={<AnonymRequest />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/machines" element={<ListMachines />} />
            <Route path="/machines/add" element={<AddMachine />} />
            <Route path="/machines/edit" element={<EditMachine />} />
            <Route path="/machines/review" element={<ReviewMachine />} />
            <Route path="/machines/view" element={<ViewMachine />} />
            <Route path="/requests" element={<ListRequests />} />
            <Route path="/requests/view" element={<ViewRequest />} />
            <Route path="/requests/edit" element={<EditRequest />} />
            <Route path="/requests-and-offers" element={<ListRequestsAndOffers />} />
            <Route path="/requests-and-offers/add" element={<AddRequestsAndOffers />} />
            <Route path="/requests-and-offers/add-location-and-date" element={<AddLocationAndDate />} />
            <Route path="/requests-and-offers/add-machine-detail" element={<AddMachineDetail />} />
            <Route path="/offers" element={<ListOffers />} />
            <Route path="/offers/add" element={<AddOffer />} />
            <Route path="/offers/view" element={<ViewOffer />} />
            <Route path="/offers/edit" element={<EditOffer />} />
            <Route path="/lessor-profile" element={<LessorProfile />} />
            <Route path="/customer-profile" element={<CustomerProfile />} />
            <Route path="/notifications" element={<Notifications />}></Route>
            <Route path="/rental-machines" element={<ListRentalMachines />} />
            <Route path="/rental-machine-detail" element={<RentalMachineDetail />} />
            <Route path="/sale-machines" element={<ListSaleMachines />} />
            <Route path="/sale-machines/detail" element={<SaleMachineDetail />} />
            <Route path="/lessor-sale-machines" element={<ListLessorSaleMachines />} />
            <Route path="/sale-machines/add" element={<AddSaleMachine />} />
            <Route path="/sale-machines/edit" element={<EditSaleMachine />} />
            <Route path="/aydinlatma-metni" element={<Agreement type="PrivacyPolicy" />} />
            <Route path="/kullanici-sozlesmesi" element={<Agreement type="UserAgreement" />} />
            <Route path="/kurumsal-ve-bireysel-uye-acik-riza-metni" element={<Agreement type="ConsentApprovalText" />} />
            <Route path="/izinli-pazarlama-onay-metni" element={<Agreement type="Ipaz" />} />
            <Route path="/hakkimizda" element={<AboutUs  />} />


        </Routes>
    );
}

export default App;
