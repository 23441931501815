import { Form } from "antd";
import OtpInput from "react18-input-otp";

export type OtpInputProps = {
    validationMessage?: string;
    placeholder?: string;
    label?: string;
    otpValue: string | undefined;
    onChange: (val: string) => void;
    numInputs?: number;
};

const OtpInputField = (props: OtpInputProps) => {
    return (
        <Form.Item label={props.label} name="sms" rules={[{ required: true, message: props.validationMessage }]}>
            <OtpInput
                inputStyle={{ margin: 10, borderRadius: 10, border: "none", width: 60, height: 60 }}
                focusStyle={{ border: "2px solid #feb111", outline: "none" }}
                errorStyle={{ borderColor: "#feb111" }}
                disabledStyle={{ borderColor: "#feb111" }}
                containerStyle={{ marginBottom: 30, justifyContent : "center" }}
                value={props.otpValue}
                onChange={(e: string) => props.onChange(e)}
                numInputs={props.numInputs}
                separator={<span>-</span>}
            />
        </Form.Item>
    );
};

export default OtpInputField;
