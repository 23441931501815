import OnboardLayout from "./onboard-layout";
import styles from "../styles/signup-layout.module.scss";

export type SignupLayoutProps = {
    children: any;
    description: string;
    descriptionBold?: boolean;
    activeTabOrder?: number;
};

const SignupLayout = (props: SignupLayoutProps) => {
    return (
        <OnboardLayout smallLogo={true}>
            <div className={styles.contentContainer} style={{ overflow: "auto" }}>
                <h1 className={props.descriptionBold ?  styles.signupHeaderTwo : styles.signupHeaderOne}>{props.description}</h1>
                {props.children}
            </div>
        </OnboardLayout>
    );
};

export default SignupLayout;
