import { t } from "../../translate";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignupLayout from "../../components/layouts/signup-layout";
import CardButton from "../../components/atomics/card-button";
import styles from "../../components/styles/signup-index.module.scss";
import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import SocialLoginButton from "../../components/atomics/social-auth-button";
import { useAtom } from "jotai";
import { postloginMessageAtom, signUpAtom, RoleType} from "../../store/global-atoms";
import { AuthType, SocialAuthPageType, SocialLoginMessageType } from "../../service";
import { useSecureService } from "../../store/service-atoms";
import { facebookLogin, googleLogin } from "../../utils/auth-utils";
import { facebookLoginActive, googleLoginActive, redirectRegisterPageUri } from "../../store/app-constants";


const Signup = () => {
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const socialLoginCode = query.get("code"); // code injected by facebook login dialog
    const [loading, setLoading] = useState(socialLoginCode !== null);
    const queryRole = ((query.get("role") || query.get("state")?.split('role:')[1]) as RoleType) ?? "lessor"; // 'state' holds roletype info for facebook redirection
    const socialLoginQuery = query.get("state")?.split('loginType:')[1].split(',')[0];
    const socialLoginType = (socialLoginQuery as AuthType) || null; // code injected by facebook login dialog
    const [selectedCard, setSelectedCard] = useState(queryRole === null ? 0 : queryRole === 'customer' ? 1 : 2);
    const [, setSignUp] = useAtom(signUpAtom);
    const [,setWarningMessage] = useAtom(postloginMessageAtom);
    const { doSocialLogin } = useSecureService();


    useEffect(() => {
        const socialLoginAsync = async (authType: AuthType) => {
            var res = await doSocialLogin(queryRole, {authType: authType, socialToken: socialLoginCode, socialAuthPageType: SocialAuthPageType.Register});
            switch(res?.type) {
                case SocialLoginMessageType.NotFound:
                case SocialLoginMessageType.SwitchMessage:
                    setSignUp({
                        authType: authType,
                        email: res.email!!,
                        name: res.name,
                        lastname: res.lastname
                    });
                    if (selectedCard === 1) { 
                        navigate("/signup/form?role=customer");
                    }
                    if (selectedCard === 2) {
                        navigate("/signup/form?role=lessor");
                    }
                    break;
                case SocialLoginMessageType.Blocked:
                    setWarningMessage({message: res.message, type: 'error'});  
                    setLoading(false);
                    break;  
                case SocialLoginMessageType.Success:
                    navigate("/dashboard?role=" + queryRole);
                    break;
                case SocialLoginMessageType.Otp:
                    navigate("/sms-verification?id=" + res?.id + "&op=register&phone=" + res?.message + "&role=" + queryRole);
                    break;
                default:
                    setLoading(false);
                    break;
            }
        }
        if (socialLoginCode && socialLoginType != null) {
            socialLoginAsync(socialLoginType);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loading ? 
                <Spin size="large" tip="Lütfen Bekleyiniz" className={styles.spinMobile} />
                : (
                    <SignupLayout description={t("signup.index.description")}>
                        <Row hidden={loading} className={styles.signupOptionsContainer} gutter={[12,4]}>
                            <Col xs={12} lg={12} className={styles.cardContainer}>
                                <CardButton
                                    icon={'user'}
                                    label={t("signup.index.firstCardTitle")}
                                    selected={selectedCard === 1}
                                    onClick={(e: any) => {
                                        setSelectedCard(1);
                                    }}
                                />
                            </Col>
                            <Col xs={12} lg={12} className={styles.cardContainer}>
                                <CardButton
                                    icon={'corp'}
                                    label={t("signup.index.secondCardTitle")}
                                    selected={selectedCard === 2}
                                    onClick={(e: any) => {
                                        setSelectedCard(2);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className={styles['button-container']} gutter={[0,12]}>
                            <Col hidden={!facebookLoginActive} className={styles['button-container-inner']}>
                                <SocialLoginButton disabled={selectedCard === 0} type="facebook" text={t("signup.facebook.button-text")} onClick={() => {
                                    if (selectedCard === 1) {
                                        facebookLogin(redirectRegisterPageUri, "loginType:" + AuthType.Facebook + ",role:customer");
                                    }
                                    if (selectedCard === 2) {
                                        facebookLogin(redirectRegisterPageUri, "loginType:" + AuthType.Facebook + ",role:lessor");
                                    }
                                }}/>
                            </Col>
                            <Col hidden={!googleLoginActive} className={styles['button-container-inner']}>
                                <SocialLoginButton disabled={selectedCard === 0} type="google" text={t("signup.google.button-text")} onClick={() => {
                                    if (selectedCard === 1) {
                                        googleLogin(redirectRegisterPageUri, "loginType:" + AuthType.Google + ",role:customer");
                                    }
                                    if (selectedCard === 2) {
                                        googleLogin(redirectRegisterPageUri, "loginType:" + AuthType.Google + ",role:lessor");
                                    }
                                }}/>
                            </Col>
                            <Col className={styles['button-container-inner']}>
                                <SocialLoginButton disabled={selectedCard === 0} type="email" text={t("signup.email.button-text")} onClick={() => {
                                    if (selectedCard === 1) {
                                        setSignUp({
                                            authType: AuthType.Email,
                                            email: ""
                                        });
                                        navigate("/signup/form?role=customer");
                                    }
                                    if (selectedCard === 2) {
                                        setSignUp({
                                            authType: AuthType.Email,
                                            email: ""
                                        });
                                        navigate("/signup/form?role=lessor")
                                    }
                                }}/>
                            </Col>
                        </Row>
                    </SignupLayout>
                )
            }
        </>

    );
};

export default Signup;
