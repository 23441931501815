import { Form } from "antd";
import PrimaryButton from "../../components/atomics/primary-button";
import TextButton from "../../components/atomics/text-button";
import AuthLayout from "../../components/layouts/auth-layout";
import { t } from "../../translate";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Lock } from "../../components/assets/ic_lock.svg";
import styles from "../../components/styles/auth-layout.module.scss";
import SmsInput from "../../components/atomics/sms-input";
import { useQuery } from "../../helpers/use-query";
import { useEffect, useState } from "react";
import { useSecureService } from "../../store/service-atoms";
import { RoleType, loadingAtom, postloginMessageAtom } from "../../store/global-atoms";
import { useAtom } from "jotai";
const maxCount = 120;
const SmsVerification = () => {
    const query = useQuery();
    const userId = query.get("id") || "0";
    const operation = query.get("op") || "";
    const phone = query.get("phone") || "";
    const roleQuery = query.get("role") as RoleType || "customer";
    const fromRequest = query.get("request") ? "&request=true" : "";
    const navigate = useNavigate();
    const [loading] = useAtom(loadingAtom);
    const [, message] = useAtom(postloginMessageAtom);
    const [counter, setCounter] = useState(maxCount);
    const { doResendActivationCode, doResendAccountActivationCode, doResetPasswordOTP, doActivateAccount } = useSecureService();
    
    const formFinishHandler = async (params: { sms: string }) => {
        if (operation === "register") {
            const result = await doActivateAccount(roleQuery, {
                code: params.sms,
                id: parseInt(userId),
            });
            if (result) {
                message({message: t('lessor.createUser.success'), type: 'success'});
                navigate("/dashboard?role=" + roleQuery + fromRequest);
            } else {
                navigate("/login?role=" + roleQuery + fromRequest);
            }
        } else {
            const result = await doResetPasswordOTP(roleQuery, {
                code: params.sms,
                id: parseInt(userId),
            });
            if (result) {
                navigate("/reset-password?phone=" + phone + "&otp=" + params.sms + "&role=" + roleQuery);
            }
        }
    };

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const resendClick = () => {
        if (operation === 'register')
            doResendAccountActivationCode(roleQuery, parseInt(userId));
        else 
            doResendActivationCode(roleQuery, parseInt(userId));
        setCounter(maxCount);
    };

    return (
        <AuthLayout>
            <div className={styles.verticalCentralizedFlexContainer}>
                <Lock width={"3.75rem"} height={"3.75rem"} />
                {`${phone}`} {t("sms-verification.info")}
            </div>
            <Form name="normal_login" className="login-form" layout="vertical" onFinish={formFinishHandler}>
                <div>
                    <SmsInput
                        placeholder={t("sms-verification.smsInput.placeholder")}
                        validationMessage={t("sms-verification.smsInput.validationMessage")}
                        label={t("sms-verification.smsInput.label")}
                    />
                </div>
                <div>
                    <PrimaryButton loading={loading} htmlType="submit">
                        {t("common.continue")}
                    </PrimaryButton>
                </div>
                <div>
                    <TextButton onClick={() => resendClick()} type="link" disabled={counter !== 0}>
                        {t("sms-verification.resend")}
                        {counter !== 0 && <span>{`(${counter} s)`}</span>}
                    </TextButton>
                </div>
            </Form>
        </AuthLayout>
    );
};

export default SmsVerification;
